body,
ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
dl,
dd,
form,
input,
textarea,
td,
th,
button,
strong,
em,
select,
video,
canvas {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

textarea {
  resize: none;
  overflow: auto;
}

img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
}

video {
  max-width: 100%;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
}

a,
input {
  -webkit-appearance: none;
  /*屏蔽阴影*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*ios android去除自带阴影的样式*/
}

a,
img {
  /* 禁止长按链接与图片弹出菜单 */
  -webkit-touch-callout: none;
}

a {
  text-decoration: none;
  background: transparent;
  /*修正win8系统IE连接灰色背景*/
  outline: none;
  blr: expression(this.onFocus=this.blur());
}

a:focus {
  outline: 0;
}

a:active,
a:hover {
  outline: 0;
}

html,
body {
  /* 禁止选中文本(如无文本选中需求,此为必选项) */
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  font-family: '微软雅黑';
  font-size: .24rem;
}

* {
  box-sizing: border-box;
  font-weight: normal;
  -webkit-appearance: none;
  /*屏蔽阴影*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*ios android去除自带阴影的样式*/
}
/*# sourceMappingURL=base.css.map */
