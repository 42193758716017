.icon-more {
    background: url('./img/icon_more.png') no-repeat center center;
}
.icon-search {
    background: url('./img/icon_search.png') no-repeat center center;
}
.icon-more-fan {
    background: url('./img/icon_more_fan.png') no-repeat center center;
}

.split {
    width:100%;
    border-bottom: 1px #cdcdcd solid;
}

.alarmIntruduce {
    width: 100%;
    height: 30px;
    background-color: rgba(255,255,255,0);
    text-align: center;
    margin-left: 20%;
    margin-top: 0px;
}

    .alarmIntruduce h1 {
        font-size: 15px;
        font-weight: 500;
        color: #696969;
        font-family: "微软雅黑"
    }

.alarmTitle {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    text-align: left;
    margin-top: 20px;
}

    .alarmTitle h1 {
        font-size: 15px;
        font-weight: 600;
        color: #A9A9A9;
        font-family: "微软雅黑"
    }

    .alarmTitle h2 {
        color: #A9A9A9;
        font-size: 10px;
        font-weight: 100;
    }

    .alarmTitle frame {
        width: 100%;
        height: 100px;
    }

.alarmContent {
    width: 100%;
    /*height:300px;*/
    background-color: #FFFFFF;
    display: none;
}

.currentAlarmMsg1 {
    width: 100%;
    /*height:130px;*/
    background-color: rgba(255,255,255,0);
    text-align: center;
    margin-top: 14%;
}

    .currentAlarmMsg1 img {
        width: 100px;
        height: 100px;
        text-align: center;
        margin-top: 1px;
    }

.currentAlarmMsg123 {
    width: 100%;
    height: 130px;
    background-color: #DCDCDC;
    text-align: center;
    margin-top: 14%;
}

    .currentAlarmMsg123 img {
        width: 100px;
        height: 100px;
        text-align: center;
        margin-top: 1px;
    }

.currentAlarmMsg {
    width: 100%;
    /*height:130px;*/
    background-color: rgba(255,255,255,0);
    text-align: center;
}

.currentAlarmMsg2 {
    width: 80%;
    /*height:130px;*/
    background-color: rgba(255,255,255,0.5);
    text-align: center;
    margin-left: 13%;
}

.currentAlarmMsg img {
    width: 80px;
    height: 65px;
    text-align: center;
    margin-top: 1px;
}

.currentAlarmMsg h1 {
    font-size: 15px;
    font-weight: 800;
    font-family: "微软雅黑"
}

.currentAlarmMsg h2 {
    font-size: 10px;
    font-weight: 100;
}
/* 预警种类 */
.warnType {
    width: 100%;
    height: auto;
    position: fixed;
    background: white;
    z-index: 120;
    left: 0;
    top: 0.65625rem;
}


.boxdiv {
    width: 25%;
    height: 0.65625rem;
    border-right: 1px #cdcdcd solid;
    border-bottom: 1px #cdcdcd solid;
    float: left;
}

    .boxdiv p {
        font-size: 0.21525rem;
        color: #333333;
        text-align: center;
        line-height: 0.65625rem;
        margin-bottom: 0;
    }


