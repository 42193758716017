body {
    padding: 0px;
    font: 12px/18px Microsoft YaHei;
    min-width: 320px;
    width: 100%;
    margin: 0 auto;
}
h4 {
    margin: 0;
    padding: 0;
}
.warncontent {
    width: 100%;
    margin: 0 auto;
}

.boxcontent {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-top: 0.43125rem;
    background: white;
}

.contenttop {
    width: 6.225rem;
    height: 0.9675rem;
    margin: 0 auto;
}

    .contenttop .warnimg {
        width: 1.65rem;
        height: 1.05rem;
        float: left;
        margin: 0 auto;
    }

    .contenttop .warnimg img {
        width: 80%;
        height: 95%;
    }

    .contenttop .warntitle {
        width: 4.51875rem;
        height:1.14375rem;
        float: left;
        line-height: 0.36rem;
    }

        .contenttop .warntitle .normal {
            font-size: 0.2625rem;
        }

        .contenttop .warntitle .toolarge {
            font-size: 0.2325rem;
        }

        .contenttop .warntitle p {
            font-family: PingFangSC-Sremibold, sans-serif;
            font-size: .2625rem;
        }

        .contenttop .warntitle span {
            color: #8e8e8e;
            font-family: PingFangSC-Medium, sans-serif;
            font-size: .24375rem;
            line-height: 0.42rem;
        }
    .contenttop .zhaiyao {
        width: .225rem;
        height: auto;
        margin: 0 auto;
        padding: .24rem;
    }
.contenttop .content1 {
    width: .225rem;
    height: auto;
    margin: 0 auto;
    padding: .24rem;
}

.content1 p {
    width: 6.225rem;
    height: auto;
    margin: 0 auto;
    font-family: PingFangSC-Medium, sans-serif;
    font-size: 0.2625rem;
    line-height: 0.36rem;
    color: #8e8e8e;
    padding: 0.46875rem 0 0.46875rem 0;
    letter-spacing: 0.005rem;
}

.split {
    width: 100%;
    height: .065625rem;
    background: #f5f7f9;
    margin: 0 auto;
}

.guide {
    width: 6.225rem;
    margin: 0 auto;
    height: auto;
}

.guidetitle {
    width: 6.225rem;
    height: auto;
}

    .guidetitle h4 {
        padding: 0.46875rem 0 0.46875rem 0;
        font-family: PingFangSC-Medium, sans-serif;
        font-size: 0.3rem;
        font-weight: 600;
    }

.guidecontent {
    width: 6.225rem;
    height: auto;
    margin: 0 auto;
}

    .guidecontent p {
        font-family: PingFangSC-Medium, sans-serif;
        font-size: 0.2625rem;
        color: #8e8e8e;
        letter-spacing: 0.005rem;
        padding-bottom: 0.3rem;
        white-space: pre-line;
        line-height: 0.36rem;
    }

.warnpublish {
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: white;
}

.publish {
    width: 6.225rem;
    margin: 0 auto;
    height: auto;
}

.publishtitle {
    width: 6.225rem;
    height: 0.8rem;
}

.warnpublish .publishtop {
    width: 6.225rem;
    height: 0.6675rem;
    margin: 0 auto;
}

    .warnpublish .publishtop .publishimg {
        width: 0.51rem;
        height: 0.51rem;
        float: left;
        margin: 0 auto;
    }

        .warnpublish .publishtop .publishimg img {
            width: 100%;
            height: 80%;
        }

    .warnpublish .publishtop .publishsender {
        width: 3.75rem;
        height: 0.51rem;
        float: left;
        line-height: 0.36rem;
    }

.publishtitle h4 {
    padding: 0.46875rem 0 0.46875rem 0;
    font-family: PingFangSC-Medium, sans-serif;
    font-size: 0.3rem;
    font-weight: 600;
}

.warnpublish .publishtop .publishsender p {
    font-family: PingFangSC-Sremibold, sans-serif;
    font-size: 0.3rem;
    line-height: 0.462rem;
}

