body {
  font-size: .24rem;
  line-height: 1.8;
  background: white;
}

.en {
  font-family: Arial, Helvetica, sans-serif;
}


.dy-wrapper {
  width: 100%;
}
.dy-header {
  width: 100%;
  padding: 2em 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.dy-header img {
  width: 48vw;
}
.dy-header .dy-header_img  {
  width: 90vw;
}
.dy-header h3 {
  margin: .5em 0;
  font-weight: bold;
  font-size: .2rem;
  color: #2a7150;
}
.dy-footer {
  width: 100%;
  padding: 2em 0;
  font-size: .26rem;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.dy-footers {
  width: 100%;
  margin-top: 10em;
  font-size: .26rem;
  color: #fff;

}
.dy-footerss {
  width: 100%;
  margin-top: 6em;
  font-size: .26rem;
  color: #fff;

}

.dy-btn {
  width: 14.4em;
  background:#1245a5;
  text-align: center;
  line-height: 2.5em;
  border-radius: 1em;
  margin: 0 auto
}
.dy-btn + .dy-btn {
  margin-top: .5em;
}
.dy-city {
  padding: .5em 1em;
}
.dy-citys {
  padding: .5em 1.32em;
}
.dy-search-wrappers {
  position: relative;
  line-height: 1;
  margin-bottom: .6em;
}
.dy-search-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  line-height: 1;
  margin-bottom: 1.6em;
  margin-top: 1.6em;
  border: 1px #cdcdcd solid;
  border-radius: 625rem 625rem;
}
.dv_img {
    float: left;width: 7%;
}
.dv_h3 {
    float: left;width: 60%;
}
.dy-search-input {
  width: 75%;
  height: 2.5em;
  font: inherit;
  font-size: .2rem;
  border-radius: .2em;
  /*padding: .4em 3em .4em 1.5em;*/
  margin-left: 1.1em;
  outline: none;

  /*//background: #ededed;*/

  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-image: initial;

}
.dy-search-btn {
  width: 1.1em;
  position: absolute;
  right: 1em;
  top: .453em;
}
.dy-city-list, .dy-type-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
}
.dy-city-item, .dy-type-item {
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: .2em;
  /*background: #ededed;*/
  border: 1px #cdcdcd solid;
  color: #6d6767;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5.75em;
      -ms-flex: 0 0 5.75em;
          flex: 0 0 5.75em;
  margin-left: .33em;
  margin-top: .4em;
  white-space: nowrap;
  text-align: center;
}
.dy-city-item, .dy-color-item {
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: .2em;
  /*background: #ededed;*/
  border: 1px #cdcdcd solid;
  color: #6d6767;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5.75em;
      -ms-flex: 0 0 5.75em;
          flex: 0 0 5.75em;
  margin-left: .33em;
  margin-top: .4em;
  white-space: nowrap;
  text-align: center;
}
.item-selected {
  background: #5ea1df;
  color: #fff;
}
.dy-type-item.item-long {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 8em;
}

.dy-color-item.item-long {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 8em;
}
.dy-types {
  padding: .4em 1em;
  padding-top: 1em;
  display: -webkit-flex;

}.dy-typess {
  padding: .4em 1em;
  padding-top: 1em;
  display: -webkit-flex;

}
.dy-type {
  padding: .4em 1em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 1em;
  margin-top: 1em;
}
.dy-wrapper-type .dy-header h3 {
  color: #20a7e9;
}
.dy-wrapper-mine .dy-header h3 {
  color: #9a99bc;
}
.dy-mine {
  padding: .4em 2em;
}
.dy-mine-item {
  width: 100%;
  margin-bottom: .75em;
  box-shadow: 0 0 .3em #f2f4fc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dy-mine-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #333;
  background: #f2f4fc;
  padding: .4em 0 .4em 1em;
}
.dy-mine-content h4 {
  font-size: .2rem;
  text-indent: .4em;
  font-weight: normal;
  border-bottom: 1px solid rgba(42, 113, 80, .2);
}
.dy-mine-types {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;

}
.dy-mine-types li {
  margin: 0 .25em;
}
.dy-mine-func {
  width: 3em;
  background: #96b5db;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.dy-mine-func img {
  width: 1em;
  line-height: 1;
}
.dy-mine-func img+img {
  margin-top: .8em;
}
/*.btn-all {*/
  /*-webkit-align-self: flex-end;*/
      /*-ms-flex-item-align: end;*/
          /*align-self: flex-end;*/
  /*line-height: 1;*/
  /*padding: .3em .5em;*/
  /*border-radius: .2em;*/
  /*border: 1px solid #2feb64;*/
  /*color: #2feb64;*/
/*}*/
.btn-all {
  transition: all .3s;
  border-radius: .2em;
  /* background: #ededed; */
  border: 1px #cdcdcd solid;
  color: #6d6767;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5.75em;
  -ms-flex: 0 0 5.75em;
  flex: 0 0 5.75em;
  margin-left: .33em;
  margin-top: .4em;
  white-space: nowrap;
  text-align: center;
}
.btn-all-color {
  transition: all .3s;
  border-radius: .2em;
  /* background: #ededed; */
  border: 1px #cdcdcd solid;
  color: #6d6767;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5.75em;
  -ms-flex: 0 0 5.75em;
  flex: 0 0 5.75em;
  margin-left: .33em;
  margin-top: .4em;
  white-space: nowrap;
  text-align: center;
}
.fixed-dy {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: .2rem;
  line-height: 1;
  background: #4c4c4c;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.fixed-dy p {
  font-size: .26rem;
}
.switch-btn {
  cursor: pointer;
  font: inherit;
  width: 2.5em;
  height: 1.4em;
  position: relative;
  border: 0px solid #dfdfdf;
  background-color: #fdfdfd;
  border-radius: 1.4em;
  background-clip: content-box;
  display: inline-block;
  -webkit-appearance: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none; 
}
.switch-btn:before {
    content: '';
    width: 1.4em;
    height: 1.4em;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 1.4em;
    background-color: #47d330;
    box-shadow: 0 0 5px inset rgba(0, 0, 0, .8); 
}
.switch-btn:checked {
    border-color: #3eec23;
    background-color: #3eec23; 
}
.switch-btn:checked::before {
    left: 1.1em; 
}
.switch-btn.switch-btn-animbg {
    -webkit-transition: background-color ease .4s;
    transition: background-color ease .4s; 
}
.switch-btn.switch-btn-animbg::before {
    -webkit-transition: left .3s;
    transition: left .3s; 
}
.switch-btn.switch-btn-animbg:checked {
    background-color: #3eec23;
    -webkit-transition: border-color .4s, background-color ease .4s;
    transition: border-color .4s, background-color ease .4s; 
}
.switch-btn.switch-btn-animbg:checked::before {
    -webkit-transition: left .3s;
    transition: left .3s; 
}
.pdf-wrapper {
  width: 100vw;
  height: 100vh;
}
.alarm-file canvas {
  max-width: 100%;
}
/*dingdian tenki*/
.commu-map-wrapper {
  position: relative;
}
.commu-feature {
  position: absolute;
  right: .6em;
  top: 1em;
  z-index: 999999;
}
.commu-feature-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.8em;
}
.commu-feature-right .feature {
  width: 2.8em;
  height: 2.8em;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.commu-feature-right .feature+.feature {
  margin-top: .5em;
}
/*.commu-feature-right .commu-city {
  background-image: url(../images/city-search.png);
}
.commu-feature-right .commu-city.active {
  background-image: url(../images/city-search-selected.png);
}
.commu-feature-right .commu-collect {
  background-image: url(../images/city-collect.png);
}
.commu-feature-right .commu-collect.active {
  background-image: url(../images/city-collect-selected.png);
}*/
.commu-feature-left {
  position: absolute;
  right: 3em;
  top: .1em;
}
.commu-left-list {
  font-size: .18rem;
  color: #5a5a5a;
  line-height: 2;
  min-width: 10em;
  max-width: 15em;
  max-height: 18em;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}
.commu-left-list.collect-1 {
  margin-top: 5em;
}
.commu-left-list.collect-2 {
  margin-top: 4.3em;
}
.commu-left-list.collect-3 {
  margin-top: 3em;
}
.commu-left-list.collect-4 {
  margin-top: 0;
}
.commu-left-list li {
  padding: 0 .4em;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}
.commu-left-list li:active, .commu-left-list li:hover {
  background: #e3e3e3;
}
.collection-show {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
/*.collection-locate {
  padding: .2em 0 .2em 1.4em;
  background: url(../images/city-location.png) left center no-repeat;
  background-size: 1em auto;
  line-height: 1.4;
}*/
/*.collection-mark {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 5em;
  height: 1.79em;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  background-image: url(../images/city-collect-add.png);
}*/
/*.collection-mark.collected {
  background-image: url(../images/city-collect-remove.png);
}*/
.ding-hour {
  width: 150%;
  padding: .6em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.ding-hour li {
  border-radius: .4em;
  padding: .6em 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ding-hour li+li {
  margin-left: .6em;
}
.ding-hour li.rain-light {
  background: #edf7ff;
}
.ding-hour li.rain-medium {
  background: #d4ebff;
}
.ding-hour li.rain-heavy {
  background: #95c4e8;
  color: #fff;
}
.ding-hour li.rain-bao {
  background: #4d99d8;
  color: #fff;
}
.ding-hour li.rain-dabao {
  background: #307fc0;
  color: #fff;
}
.ding-hour li.rain-tedabao {
  background: #095ea2;
  color: #fff;
}
.ding-hour li img {
  width: 60%;
}
.commu2 .weather-temp {
  background: #fff;
  padding: 1em;
  margin: 0;
  border-bottom: 1px solid #e4e6e9;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.commu2 .weather-temp .temp-container {
  margin-top: .6em;
}
.commu2 .weather-temp .temp-number {
  font-size: .62rem;
}
.commu2 .weather-temp .temp-text {
  padding: 0;
  margin-left: .2em;
}
.commu2 .temp-text .temp-tq {
  font-weight: normal;
}
.commu2 .weather-temp .temp-circle {
  width: .6em;
  height: .6em;
  border: .08em solid #282828;
}
.commu2 .weather-temp .temp-info {
  margin-left: 0;
}
.commu2 .temp-info li {
  font-weight: normal;
}
.commu2 .temp-info .air {
  padding-left: 0;
}
.commu-amap-locate {
  width: 31px;
  height: 37.5px;
  /*background: url(../images/city-map-locate.png) left top no-repeat;*/
  background-size: 100% 100%;
  position: relative;
  left: -50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.commu-amap-locate img {
  width: 45%;
  margin-bottom: 10px;
}
.commu2 .params-container {
  background: #fff;
}
.setting-wrapper {
  height: 100vh;
  padding-bottom: 12.3vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.setting-btn {
  width: 67vw;
  font-size: .36rem;
  line-height: 2.2;
  text-align: center;
  color: #fff;
  background: #359eff;
  border-radius: 5px;
}
.setting-list {
  width: 100%;
}
.setting-item {
  width: 100%;
  font-size: .3rem;
  padding: .8em;
  padding-left: 2.8em;
  border-bottom: 2px solid #d4d4d4;
  background-position: left .8em center;
  background-repeat: no-repeat;
  background-size: 1.4em auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
/*.setting-item.fly {
  background-image: url(../images/setting-fly.png);
}
.setting-item.locate {
  background-image: url(../images/setting-locate.png);
}
.setting-item.forecast {
  background-image: url(../images/setting-forecast.png);
}
.setting-checkbox {
  width: 1.4em;
  height: 1.4em;
  background: url(../images/setting-unselect.png) center no-repeat;
  background-size: 100% auto;
  -webkit-transition: background .5s;
  transition: background .5s;
}
.setting-item.checked .setting-checkbox {
  background-image: url(../images/setting-selected.png);
}*/
.setting-icon {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 999;
  width: 14vw;
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  html {
    font-size: 70px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 720px) {
  html {
    font-size: 80px !important;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1440px) {
  html {
    font-size: 160px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  html {
    font-size: 220px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  html {
    font-size: calc(50px + (300 - 50) * ((100vw - 320px)/(1920 - 320))) !important;
  }
}
/*# sourceMappingURL=styles.css.map */

.btn-switch {
  cursor: pointer;
  width: 52px;
  height: 31px;
  position: relative;
  border: 1px solid #dfdfdf;
  background-color: #fdfdfd;
  box-shadow: #dfdfdf 0 0 0 0 inset;
  border-radius: 20px;
  background-clip: content-box;
  display: inline-block;
  -webkit-appearance: none;
  user-select: none;
  outline: none !important;
}

.btn-switch.large {
  width: 70px;
  height: 30px;
  border-radius: 30px;
}

.btn-switch.large:before {
  content: '';
  width: 28px;
  height: 28px;
  border-radius: 30px;
}

.btn-switch.large:after {
  content: '关闭';
  color: #999;
  left: 30px;
  position: absolute;
  line-height: 30px;
  font-size: 14px;
}

.btn-switch.large:checked:after {
  content: '开启';
  color: #fff;
  left: 10px;
  position: absolute;
  line-height: 30px;
  font-size: 14px;
}

.btn-switch.large:checked:before {
  left: 40px;
}

.btn-switch:before {
  content: '';
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.btn-switch:checked {
  /*border-color: #64bd63;*/
  border-color: #5ea1df;
  box-shadow:  #5ea1df 0 0 0 16px inset;
  background-color:  #5ea1df;
}

.btn-switch:checked:before {
  left: 21px;
}

.btn-switch.btn-switch-animbg {
  transition: background-color ease 0.4s;
}

.btn-switch.btn-switch-animbg:before {
  transition: left 0.3s;
}

.btn-switch.btn-switch-animbg:checked {
  box-shadow: #dfdfdf 0 0 0 0 inset;
  background-color: #64bd63;
  transition: border-color 0.4s, background-color ease 0.4s;
}

.btn-switch.btn-switch-animbg:checked:before {
  transition: left 0.3s;
}

.btn-switch.btn-switch-anim {
  transition: border cubic-bezier(0, 0, 0, 1) 0.4s, box-shadow cubic-bezier(0, 0, 0, 1) 0.4s;
}

.btn-switch.btn-switch-anim:before {
  transition: left 0.3s;
}

.btn-switch.btn-switch-anim:checked {
  box-shadow: #64bd63 0 0 0 16px inset;
  background-color: #64bd63;
  transition: border ease 0.4s, box-shadow ease 0.4s, background-color ease 1.2s;
}

.btn-switch.btn-switch-anim:checked:before {
  transition: left 0.3s;
}

input[type=checkbox]:checked::before {
  font: normal normal normal 14px/2 FontAwesome;
}