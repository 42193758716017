/* Provide sufficient contrast against white background */
@import '~rc-easyui/dist/themes/material-blue/easyui.css';
@import '~rc-easyui/dist/themes/icon.css';
@import '~rc-easyui/dist/themes/react.css';

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.top {
    width: 100%;
    height: 5rem;
}

.top .con {
    width: 90%;
    height: 3rem;
    margin: 0 auto;
    margin-top: 2rem;
}

.top .con .top_left {
    float: left;
    width: 21%;
}

    .top .con .top_left img {
        width: 80%;
        border-radius: 100%;
    }

.top .con .top_right {
    float: left;
    width: 60%;
    height: 3rem;
}

.top .con .top_right p {
font-size: 1rem;
line-height: 3.3rem;
}

.line {
    width: 100%;
    height: .75rem;
    background: #ebebeb;
}

